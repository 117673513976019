import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import ContactUs from './components/pages/ContactUs';
import Footer from './components/Footer';
import About from './components/pages/about';
import Product from './components/pages/product';
import Home from './components/pages/Home'



function App() {
 

  return (
    <div className="page-container">
      <div className="content-wrap">
        <Router>
        <Navbar />
        <Switch>
          <Route exact path = "/">
           <Home />
          </Route>
          <Route path='/home' component={Home} />
          <Route path='/aboutus' component={About} />
          <Route path='/product' component={Product} />
          <Route path='/contact-us' component={ContactUs} />
        </Switch>
        </Router>
      </div>
      <Footer className = 'Footer'/>
    </div>
  );
}

export default App;
