import React from 'react'
import './product.css'
import Woven from '../pics/Woven.jpg'
import Scrub from '../pics/Scrub.jpg'
import TCtwill from '../pics/TCtwill.jpg'
import polycotton from '../pics/polyCottonTwill.jpg'
import Cotton from '../pics/Cotton.jpg'

const Products = () => {
    return (
        <div className="All-product">
            <div className="Header-Text">
                    <p>Everything about textiles.</p>
                    <p>From initiate to finale.</p>
                    <h3>From yarn warping, sizing, weaving to dyeing and finishing, our vertically </h3>
                    <h3> integrated production facilities serves our customers with various demands.</h3>  
            </div>
            <div className="Promote-Text">
                <div className = "Main-Pic Maintxt">
                    <h1>Dyeing Services</h1>
                </div>
                <div className = "Sub-Pic">
                    <div className = "Sub-Pic1 Maintxt">
                        <h1>Yarns</h1>
                    </div>
                    <div className = "Sub-Pic2 Maintxt">  
                        <h1>Greige Fabrics</h1>
                    </div>
                </div>
            </div>
            <p className = "Header-Product"> Our Fabric </p>
            <div className="Our-Product">
                    <div>
                    <img src = {Woven} />
                    <p>Woven</p>
                    </div>
                    <div>
                    <img src = {TCtwill} />
                    <p>TC twill </p>
                    </div>
                    <div>
                    <img src = {polycotton} />
                    <p>Poly/cotton twill</p>
                    </div>
                    <div>
                    <img src = {Scrub}/>
                    <p>Scrub</p>
                    </div>
                    <div>
                    <img src = {Cotton} />
                    <p>Cotton</p>
                    </div>
            </div>
        </div>
    
    )
}

export default Products