import React from 'react'
import './aboutus.css'
import imgLogo from '../pics/TIB-LOGO.png'
import imgPlace from '../pics/Tib-Place - Copy.jpg'

const Products = () => {
    return (
        <div className = "All-aboutus">
            <img src = {imgPlace} alt="About-TIB" className = "About-TIB"/>
        
        <div className = "Profile box">
            <div>
            <h1>Company Profile</h1>
            <h3> 
            Thai roong inter biz has been established since February 1989.
            We have set a goal to be one of the leader in supplying quality textiles to the garment industry and wholesalers either for domestic and oversea markets.
            Now, we have expanded our market covering from eastern part to the west of the world from Asean, South Asians ex. Korea, Japan. Moreover, we also export to Middle East, Europe and Western of south America.
            </h3>
            </div>
            <img src = {imgLogo} alt="About TIB" className = "About-TIB"/>
        </div>
        <div className = "Mission box">
            <h1> Vision </h1>
            <h3>
            <p>We have our vision as a company to work/meet with all valued customer's requirement with</p>
            <p>- Competitive</p>
            <p>- Quality - Orientation and Frank Opinion</p>
            <p>- Sincerity + Trust-Worthy</p>
            </h3>
        </div>
        <div className = "Specialist box">
            <h1> Mission</h1>
            <h3> 
                <p>- Meet customer's expectation under our face to face discussion and openness</p>
                <p>- Long term friendship and relation</p>
            </h3>
        </div>
        <div className = "Additional box">
            
        </div>
        </div>
        
    )
}

export default Products