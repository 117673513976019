import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  {
    markerOffset: -20,
    name: "Vietnam",
    coordinates: [108.277199, 14.058324]
  },
  { markerOffset: 15, name: "Indonesia", coordinates: [113.921326, -0.789275] },
  { markerOffset: -20, name: "Laos", coordinates: [102.495499, 19.856270] },
  { markerOffset: -24, name: "Malaysia", coordinates: [109.45547499999998, 4.1093195] },
  { markerOffset: 15, name: "Myanmar", coordinates: [96.68302949999998, 19.163916] },
  { markerOffset: 15, name: "South Korea", coordinates: [127.73588949999998, 35.9016955] },
  { markerOffset: -30, name: "Japan", coordinates: [134.379711, 34.886306] },
  { markerOffset: 10, name: "Jordan", coordinates: [37.130583, 31.276778] },
  { markerOffset: 15, name: "Saudiarabia", coordinates: [45.08113850000001, 23.8862915] },
  { markerOffset: 15, name: "Pakistan", coordinates: [69.35976599999998, 30.441861000000003] },
  { markerOffset: 15, name: "India", coordinates: [82.79499799999996, 21.125681] },
  { markerOffset: -30, name: "Bangladesh", coordinates: [90.351002, 23.687639500000003] },
  { markerOffset: 8, name: "Netherlands", coordinates: [5.29525000000001, 52.133057] },
  { markerOffset: -30, name: "United Kingdom", coordinates: [-3.432277499999998, 54.633221000000006] },
  { markerOffset: 15, name: "Spain", coordinates: [-2.487694499999975, 39.896026500000005] },
  { markerOffset: 15, name: "Romania", coordinates: [24.9805135, 45.9471245] },
  { markerOffset: 15, name: "Peru", coordinates: [-75.002365, -9.1813525] },
  { markerOffset: 15, name: "Venezuela", coordinates: [-66.5789265, 6.4141070000000004] }
];

const MapChart = () => {
  return (
    <ComposableMap
      style={{
        margin: "0",
        padding: "0",
        width: "100%",
        maxHeight: "100vh",
        overflow: "hidden"
   }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} 
            fill="#EAEAEC"
            stroke="#D6D6DA"
            />)
        }
      </Geographies>
      {markers.map(({ name, coordinates, markerOffset }) => (
        <Marker key={name} coordinates={coordinates}>
          <g
            fill="none"
            stroke="#FF5533"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(-12, -24)"
          >
            <circle cx="12" cy="10" r="3" />
            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
          </g>
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "PT Sans, sans-serif", fill: "#e8a628" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default MapChart;