import React from 'react'
import Testimg from '../pics/TIB-LOGO.png'
import './ContactUs.css'

const General_info = 'For inquiries related to sales or any questions about our products and services,\nplease don’t hesitate to reach out to our professionals through different channels below.\nThe inquiries can be related to any of our team.\n\n'
const openHours = 'Office hours:\nMon-Sat, 08.00-17.00\nTel: +66(02) 913-3380-3\nFax: +66(02) 587-8053\nE-mail:'
const Office = 'THAI ROONG INTER BIZ CO., LTD \nHead Office: \n8/1 5th Floor Soi Chotiwat Soi 2\nBangsue District Bangsue Sub District\nBangkok Thailand 10800 '

const ContactUs = () => {
  
    return (  
           <div className ="Contact-form">
           <div className="Header-Content">
               <h1>Contact us.</h1>
            </div>
           <div className="General-information">
               <div className="General-content">
                <h1>Sales and General information</h1>
                <h2>{General_info}</h2>
                <h2>{openHours}</h2>
                <h2><a href="mailto:chai@tib.co.th?&subject=Request%20for%20quotation&body=Attn:Chairat"> - chai@tib.co.th </a> (Attn: Chai) for Europe, Middle East and South Asia market </h2>
                <h2><a href="mailto:ekk@tib.co.th?&subject=Request%20for%20quotation&body=Attn:Ekk">- ekk@tib.co.th </a> (Attn: Ekk) for Australia, East Asia and Asian market</h2>
                </div>
                <img className ="imgPlace" src={Testimg}/> 
           </div>
           <div className = "flex-container">
                <div className = "General-info">
                    <h2>{Office}</h2>
                </div>
                {/*<div classname = "Map-Information">*/}
                    <iframe className = "Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d484.29742466894186!2d100.5368166749365!3d13.8162421525618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d0bf134aa47%3A0x58a1bc429c19c698!2sTHAIROONG%20INTERBIZ%20CO.%2C%20LTD!5e0!3m2!1sth!2sth!4v1604912778602!5m2!1sth!2sth" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"> </iframe>    
                {/*</div>*/}
            </div>
           {/*<div className= "Contact-space"></div>
           <div className="flex-contact"> 
                <ContactForm /> 
            </div>*/}
        </div>
           
    )
}

export default ContactUs    