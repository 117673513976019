import React from 'react'
import OCS from '../pics/OCS-Logo.png'
import Okotex from '../pics/Oeko-tex_STANDARD_100.png'
import GRS from '../pics/GRS-Label.png'
import BCI from '../pics/BCI.png'
import './Home.css'
import MapChart from "../Mapchart";


const Home = () => {
    return (
        <div className = "All-Home">
            <div>
                <img src = "https://images.unsplash.com/photo-1468926923143-b730447daf7d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80" alt="Describe Fabric Type" className = "TestPic2"/>
                <div className = "Open-content">
                    <h3>THAI ROONG INTER BIZ CO., LTD</h3>
                    <p>THE LEADING THAILAND TEXTILES COMPANY</p>
                </div>
            </div>
            <div className = "quoteDay">
                {/*<p>Enhance customer product by our services</p>*/}
                <p>We supply product more than 10 countries... </p>
            </div>
            <div className="quote-invite">
            <MapChart />
            {/*<h1>Market </h1>
            <p></p>
            <h3> 
                <p>Our fabric destiny to valued clients</p>
                <p>Asian Buyer : Vietnam, Indonesia, Malaysia, Laos and Myanmar</p>
                <p>East Asia   : Korea, Japan</p>
                <p>South Asian and Middle East : Dubai, Jordan, Saudiarabia, Pakistan, India, Bangladesh</p>
                <p>Europe and Australia : Holland, United Kingdom, Spain, Romania</p>
                <p>South Africa : Peru, Venezuela</p>
            </h3>*/}
            </div>
            <div className="Certificate">
                 <div className="Cert-Content">
                        <h1> Our Certificate</h1>
                        <article> Customers can trust our products which reach the global standard.</article>
                </div>
                <div className="Cert-Pic">
                    {/* <img src ={OCS}/> */}
                    <img src ={Okotex}/>
                    {' '}
                    {/* <img src ={GRS}/> */}
                    <img src ={BCI}/>         
                </div>       
            </div>
        </div>
    
    )
}

export default Home