import React from 'react'
import './Footer.css'


function Footer() {
    return (
        <div className='Footer'>
           &copy;{new Date().getFullYear()} THAI ROONG INTER BIZ CO.,LTD
        </div>
    )
}

export default Footer