import React, {useState} from 'react'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import './Navbar.css'
import Dropdown from './Dropdown'
import Testimg from './pics/TIB-LOGO.png'


function Navbar (){
    const [click, setClick] = useState(false)
    const [dropdown, setDropdown] = useState(false);

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(true);
        }
      };
    
      const onMouseLeave = () => {
        if (window.innerWidth < 960) {
          setDropdown(false);
        } else {
          setDropdown(false);
        }
      };


    return (
        <>
        <nav className='navbar'>
            <Link to='/' className='navbar-logo'>
                {/*TIB <i className='fab fa-firstdraft' />
                TIB */}<img className = "CompanyLogo" src={Testimg} width="50" height="50"/ >  
            </Link>
           
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                
                <li className='nav-item'>
                    <Link to='/home' className='nav-links' onClick={closeMobileMenu}>
                        Home
                    </Link>
                </li>
                <li
                    className='nav-item'
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <Link
                    to='/aboutus'
                    className='nav-links'
                    onClick={closeMobileMenu}
                    >
                    About Us {/*<i className='fas fa-caret-down' /> */}
                    </Link>
                    {/*} {dropdown && <Dropdown />} */}
                </li>
                <li className='nav-item'>
                    <Link to='/product' className='nav-links' onClick={closeMobileMenu}>
                        Products
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/contact-us' className='nav-links' onClick={closeMobileMenu}>
                        Contact Us
                    </Link>
                </li>
                {/*<li className='nav-item'>
                    <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                        Sign Up
                    </Link>
                </li> */}
            </ul>
             {/* <Button /> */}
        </nav>
        </>
    
    )
}

export default Navbar
